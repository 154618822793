import React, { useState } from 'react';
import TextType from '../../components/TextType';
import { GatsbyImage } from "gatsby-plugin-image";
import loadable from '@loadable/component';

const TestimonialsSectionRo = loadable(() => import('../../components/testimonials-section/TestimonialsSectionRo'));

export default function AndDigitalTestimonial({ data, language }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={"bg-gray-100 h-auto flex flex-col px-10 cover-svg mt-10"}>
     <div className={"flex flex-col text-center md:flex-row w-full lg:px-10"}>
       <span className={"text-lg md:text-xl lg:text-2xl xl:text-3xl text-primary-default text-center pt-5 font-light px-2 w-full md:w-1/2 md:text-right"}>{data?.frontmatter?.andDigitalHeaderStart}</span>
       <div className={"flex flex-row text-center justify-center"}>
       <TextType textClasses={"text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-400 md:pt-5 font-light"} blinkClasses={"text-lg md:text-xl lg:text-2xl xl:text-3xl md:pt-5 font-light"} blinkColor={" text-gray-400"} blinkDefaultColor={"text-gray-100"} words={data?.frontmatter?.andDigitalHeaderMiddle} />
       </div>
       <span className={"text-lg md:text-xl lg:text-2xl xl:text-3xl text-primary-default text-center md:pt-5 font-light px-2"}>{data?.frontmatter?.andDigitalHeaderEnd}</span>
     </div>
     <div>
      <h2 className={"text-primary-default uppercase text-center font-light py-5"}>{data?.frontmatter?.andDigitalSubheader}</h2>
     </div>
     <div className={"w-full lg:w-2/3 self-end mt-5 p-5 bg-primary-blockquote rounded-xl text-white and-blockquote self-center flex flex-row"}>
       <div className={"flex flex-col"}>
        <p className={"p-5"}>{data?.frontmatter?.andDigitalQuoteText}</p>
         <div className={"w-1/2 xl:w-1/3 xxl:w-1/4 self-end"}>
           <a href={data?.frontmatter?.andDigitalLogoUrl} target={"_blank"}><GatsbyImage
             image={data?.frontmatter?.andDigitalLogo?.childImageSharp?.gatsbyImageData} alt={data?.frontmatter?.andDigitalLogoAlt}
             className={"and-digital-logo cursor-pointer"} /></a>
         </div>
       </div>
     </div>
      <div className={"flex flex-col"}>
        <div className={"self-center w-full lg:w-2/3"}>
          <div className={"and-digital-arrow-down"}></div>
        </div>
        <div className={"w-full lg:w-2/3 self-end mb-5 p-5 self-center flex flex-row"}>
          <GatsbyImage
            image={data?.frontmatter?.andDigitalPersonPicture?.childImageSharp?.gatsbyImageData} alt={data?.frontmatter?.andDigitalPersonPictureAlt}
            className={"w-12 h-11 md:h-auto rounded-full mr-2"} />
          <div className={"flex flex-col w-full text-sm text-gray-600"}>
            <p className={"font-bold"}>{data?.frontmatter?.andDigitalName}</p>
            <p>{data?.frontmatter?.andDigitalFunction}</p>
          </div>
        </div>
      </div>
      <div className={"py-10"}>
        <TestimonialsSectionRo expanded={expanded} setExpanded={setExpanded} />
      </div>
    </div>
  );
}
