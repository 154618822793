import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

export default function Testimonial({ testimonial, index, language }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div id={testimonial?.refId} className={"flex flex-col border-t-2 border-gray-100 mt-5"} key={index}>
      <ReactTooltip className={"w-full md:w-1/4 lg:w-1/4 px-5 data-tooltip"} backgroundColor={"#00ab6b"} place={'top'} multiline effect={'solid'} />
      <div className={"flex flex-col mt-10" + (testimonial?.imagePosition === 'left' ? ' md:flex-row' : ' md:flex-row-reverse')}>
        <img loading="lazy" src={testimonial?.image?.publicURL} alt={testimonial?.alt} className={"w-1/2 md:w-1/3 lg:w-1/3 self-center md:self-start"} />
        <div className={"flex flex-col md:ml-5 w-2-3 md:w-2/3 lg:w-3/4"}>
          <h2 className={"text-lg md:text-xl lg:text-2xl text-primary-default text-left pt-5 font-light"}>{testimonial?.title}</h2>
          <div className={"mt-5 text-gray-500 lg:text-lg"}>
            {testimonial?.body && testimonial.body.map((text, index) => <p key={index} className={"mb-5"}>{text}</p>)}
          </div>
          <div className={"w-full lg:w-2/3 self-end my-5 p-5 bg-gray-100 rounded-xl text-sm text-gray-500 blockquote cursor-pointer"}
            onClick={() => {
                setExpanded(!expanded);
            }}
          >
            <span>{testimonial?.testimonialExcerpt} {!expanded && <span className={"text-primary-default"}>(...more)</span>}</span>
            {expanded && testimonial?.testimonialText.map((text, index) => <p key={index} className={"text-primary"}>{text}</p>) }
            {expanded && <span className={"text-primary-default"}>(...less)</span>}
            <br/>
            <br/>
            <p className={"text-primary-default text-right"}>{testimonial?.person}</p>
            <a href={testimonial?.companyUrl} target={"_blank"} className={"text-primary-default text-right float-right hover:underline"}>{testimonial?.company}</a>
          </div>
        </div>
      </div>
      <div className={"flex flex-row flex-wrap my-5"}>
        {testimonial?.iconTitles?.map((iconTitle, index) => (
          <div key={index} data-tip={testimonial?.iconDescriptions[index]} className={"flex flex-row w-full md:w-1/4 items-center p-5 cursor-pointer"}>
            <div className={"text-primary-default p-3 h-6 w-6 md:h-8 md:w-8 flex items-center justify-center"}>
              <i className={testimonial?.iconImageClasses[index] + " testimonial-icon"} />
            </div>
            <p className={"text-lg md:text-xl text-primary-default font-light ml-4"}>{testimonial?.iconTitles[index]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
