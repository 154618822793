import React  from 'react';
import loadable from '@loadable/component'
const AppleStore = loadable(() => import('../../svg/AppleStore'));
const GooglePlay = loadable(() => import('../../svg/GooglePlay'));

export default function Mobile({ data }) {
  return (
    <div className={"flex flex-col md:flex-row px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96   py-10 w-full items-center justify-center"}>
      <div className={"flex flex-col w-full lg:w-1/3"}>
        <h2 className={"text-lg md:text-xl lg:text-2xl text-primary-default pt-5 font-light w-full"}>{data?.frontmatter?.mobileAppTitle}</h2>
        <p className={"w-full mt-2 text-gray-500 mb-5 md:mb-0"}>{data?.frontmatter?.mobileAppLeftDescription}</p>
      </div>
      <div className={"mobile-image px-5 w-full h-full lg:w-1/3"}>
        <img loading="lazy"
          src={data?.frontmatter?.mobileAppPicture.publicURL}
          alt={data?.frontmatter?.mobileAppPictureAlt}
          className={"w-full h-full"} />
      </div>
      <div className={"w-full lg:w-1/3 flex flex-col mt-5 md:mt-0"}>
        <p className={"w-full mt-2 text-gray-500 mb-5 md:mb-0"}>{data?.frontmatter?.mobileAppRightDescription}</p>
        <div className={"flex flex-row self-center"}>
            <AppleStore className={"px-2"}/>
            <GooglePlay className={"px-2"}/>
        </div>
      </div>
    </div>
  );
};
