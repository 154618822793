import React from 'react';
import Testimonial from '../../components/testimonial';

export default function Testimonials({ testimonials, language }) {
  return testimonials && testimonials.length ? (
    <div className={"px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  "}>
      {testimonials.map((testimonial, index) => <Testimonial language={language} testimonial={testimonial} index={index} key={index} /> )}
    </div>
  ) : null;
}
