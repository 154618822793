import React, { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../svg/CloseIcon';
import ReCaptcha from '@matt-block/react-recaptcha-v2';
import axios from 'axios';

Modal.setAppElement("#portal");

export default function RequestDemoModal({ isOpen, setIsOpen, data }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [loadCaptcha, setLoadCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const errorMessages = {
    en: {
      notEmpty: "This field is mandatory.",
      email: "Please enter a valid email address.",
      api: "An error has occurred when processing your request. Please make sure all fields are valid and try again."
    }
  };

  const handleChange = (field, event) => {
    if (field === 'email') {
      setEmail(event?.target?.value);
    } else {
      setMessage(event?.target?.value);
    }

    setLoadCaptcha(true);
  };

  const onSubmit = () => {
    if (validateForm()) {
      const data = {
        Name: email,
        Email: email,
        PhoneNumber: '',
        Message: 'Demo request: ' + message,
        CaptchResponse: captcha
      };
      axios.post("https://app.elapseit.com/api/websitecontact/SubmitContact", data).then(r => {
        setShowSuccess(true);
      }, error => setErrorMessage(errorMessages.en.api));
    }
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrorMessage(null);
    setShowSuccess(false);
    let toReturn = true;

    if (!emailRegex.test(email)) {
      setEmailError(errorMessages.en.email);
      setErrorMessage(errorMessages.en.general);
      toReturn = false;
    } else {
      setEmailError(null);
    }

    return toReturn;
  }

  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Request demo"
      className={"DemoModal"}
    >
      <div className={"bg-white w-full rounded-xl contact-us-form demo-modal"}>
        <div onClick={() => setIsOpen(false)} className={"fixed top-0 right-0 px-1 pt-0.5 z-50 cursor-pointer"}><CloseIcon height={25} width={25} fill={'#FFFFFF'}/></div>
        <div className={"rounded-t-xl pl-5 pt-1 pb-1 bg-primary-default text-white font-light tracking-wide"}>
          {data?.frontmatter?.demoHeader}
        </div>
        <div className={"p-5"}>
          <div>{data?.frontmatter?.demoText}</div>
          <div className={"form-input mt-5"}>
            <i className={data?.frontmatter?.demoEmailIcon} />
            <input type={'email'} placeholder={data?.frontmatter?.demoEmailPlaceholder} value={email}
                   onChange={(event) => handleChange('email', event)} />
            <span className={"text-sm text-red-600 ml-5"}>{emailError}</span>
          </div>
          <div className={"form-input"}>
            <i className={data?.frontmatter?.demoNotesIcon} />
            <textarea className={'disable-resize'} placeholder={data?.frontmatter?.demoNotesPlaceholder} value={message}
                      onChange={(event) => handleChange('message', event)} />
            <span className={"text-sm text-red-600 ml-5"}>{messageError}</span>
          </div>

          {loadCaptcha && <ReCaptcha
            siteKey="6LcamqEcAAAAAD1jKWj-t8J-FvR5fFL2ggE_oQ-X"
            theme="light"
            size="normal"
            onSuccess={(captcha) => setCaptcha(captcha)}
            onExpire={() => setCaptcha(null)}
          />}

          <span className={"text-sm text-red-600 mt-2"}>{errorMessage}</span>
          {showSuccess &&
          <span className={"text-sm text-primary-default ml-5 mt-2"}>Your message has been sent.</span>}

          <div onClick={onSubmit}
               className={"mt-2 uppercase text-primary-default cursor-pointer border-2 rounded-2xl w-64 text-center border-primary-default px-5 py-2 hover:bg-primary-default hover:text-white font-bold"}>
            {data?.frontmatter?.demoSubmitButtonText}
          </div>
        </div>
      </div>
    </Modal>
  )
};
