import React from 'react';

export default function BottomText({ data }) {
  return (
    <div className={"flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96   py-10 w-full items-center justify-center"}>
      <h2 className={"text-xl md:text-xl lg:text-2xl text-primary-default text-center pt-5 font-light"}>{data.frontmatter.somethingMissingTitle}</h2>
      <div className={"mt-5"}>
        {data.frontmatter.somethingMissingDescription.map((item, index) => <p className={"text-sm text-gray-500 text-center mb-2"} key={index}>{item}</p>)}
      </div>
    </div>
  )
}
