import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import "react-multi-carousel/lib/styles.css";
import { whitespacePaddingLimit } from '../utils/constants';
import loadable from '@loadable/component';
import { Cookies } from 'react-cookie-consent';
const HomepageCarousel = loadable(() => import('./homepage-carousel'));

export default function HomepageFeatures({ diveInText, tryItTexts, language }) {
  const data = useStaticQuery(graphql`
  query HomepageFeatures {
    features: allMdx(filter: {frontmatter: {type: {in: ["feature"]}}}) {
      posts: nodes {
        id
        fields {
            slug
        }
        exports {
          categories {
            name
            index
          }
        }
        frontmatter {
          language
          type
          excerpt
          cardTitle
          cover {
            publicURL
          }
          coverAlt
        }
      }
    }
  }
`);

  const relatedFeatures = data?.features?.posts?.filter(x => x.frontmatter.type === "feature" && x.frontmatter.language === language);

  for (let relatedFeature of relatedFeatures) {
    if (relatedFeature.frontmatter?.excerpt.length < whitespacePaddingLimit) {
      let whiteSpace = "";
      for (let index = relatedFeature.frontmatter?.excerpt.length; index < whitespacePaddingLimit; index++) {
        whiteSpace = whiteSpace + " ";
      }
      relatedFeature.whiteSpace = whiteSpace;
    }
  }

  return relatedFeatures && relatedFeatures.length && (
    <div className={"flex flex-col w-full mt-10"}>
      <h2 className={"text-center text-xl md:text-2xl text-primary-default font-light"}>{diveInText}</h2>
      <p className={"text-center pt-2 text-gray-500"}>{tryItTexts && tryItTexts.length && tryItTexts[0]} <a href={"https://app.elapseit.com/register"} target={"_blank"}>{tryItTexts && tryItTexts.length && tryItTexts[1]}</a>{tryItTexts && tryItTexts.length && tryItTexts[2]}</p>
      <HomepageCarousel relatedFeatures={relatedFeatures} language={language} />
    </div>
  );
}
