import React, { useState, useEffect } from "react";

export default function TextType({ words, textClasses, blinkClasses, blinkColor, blinkDefaultColor }) {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
      if (words) {
        if (index === words.length) {
          setIndex(0);
          return;
        }

        if (subIndex === words[index].length + 1 &&
          index !== words.length && !reverse) {
          setReverse(true);
          return;
        }

        if (subIndex === 0 && reverse) {
          setReverse(false);
          if (index < words.length) {
            setIndex((prev) => prev + 1);
          } else {
            setIndex(0);
          }
          return;
        }

        const timeout = setTimeout(() => {
          setSubIndex((prev) => prev + (reverse ? -1 : 1));
        }, Math.max(reverse ? 50 : subIndex === words[index].length ? 3000 :
          50, parseInt(Math.random() * 25)));

        return () => clearTimeout(timeout);
      }
  }, [subIndex, index, reverse, words]);

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return words && words.length ? (
    <>
      <span className={textClasses} key={index}>
        {`${words[index] && words[index].length && words[index].substring(0, subIndex)}`}
      </span>
      <span className={blinkClasses + (blink ? blinkColor : (blinkDefaultColor ? " " + blinkDefaultColor : " text-white"))} key={index + "z"}>
        |
      </span>
    </>
  ) : null;
}
